import React, { useCallback, useEffect, useMemo, useRef } from 'react'
import {
  FeedItem,
  useGetGlobalFeed,
  GlobalFeedPeriodType,
  GlobalFeedSortType,
  GetGlobalFeedParams,
  FeedReason,
  GlobalFeedOutput
} from '@commonstock/common/src/api/feed'
import { BrandSpinner } from '../../components/Spinner'
import { Spacing } from '../../composing/Spacing'
import AllCaughtUp from './AllCaughtUp'
import { usePersistedState } from '@commonstock/common/src/utils/usePersistedState'
import { CardLayoutTypes, GlobalFeedPeriodKey, GlobalFeedSortKey } from './constants'
import { motion } from 'framer-motion'
import FeedSkeleton from './Feed.skeleton'
import { ScrollerClass } from '../nav/StandardScreen'
import { CSText } from '../../composing/CSText'
import { useUser } from '../auth/AuthContext'
import { Routes } from '../nav/constants'
import FeedCreatePost from './FeedCreatePost'
import { useRouter } from 'next/router'
import { DateTime } from 'luxon'
import { RequestStage } from '@commonstock/client/src/constants'
import { SkeletonWrapper } from '../../components/SuspenseSkeleton'
import GlobalFeedFilters from 'src/scopes/feed/GlobalFeedFilters'
import PaneHeader from 'src/scopes/nav/PaneHeader'
import { isLoggedIn as isLoggedInMethod } from '@commonstock/common/src/auth'
import { PaneColumn, PanelGrid } from 'src/components/styles'
import HomeDashboard from '../dashboard/HomeDashboard'
import { ZIndexLayers } from 'src/theme/constants'
import { useClient } from '@commonstock/client/src/react/context'
import useForceRender from 'src/utils/forceRender'
import InAppMessageList from './InAppMessageList'
import WindowedFeed, { ContentTypeCard } from './WindowedFeed'

type Props = {
  publicTopFeed?: GlobalFeedOutput
  publicNewFeed?: GlobalFeedOutput
  ssrPeriod?: GlobalFeedPeriodType
}
function GlobalFeed({ publicTopFeed, publicNewFeed, ssrPeriod }: Props) {
  const isLoggedIn = useMemo(() => isLoggedInMethod(), [])
  const [user] = useUser()

  const [period, setPeriod, periodPending] = usePersistedState<GlobalFeedPeriodType>(
    GlobalFeedPeriodKey,
    GlobalFeedPeriodType.RECENT
  )
  const [sortBy, setSortBy, sortPending] = usePersistedState<GlobalFeedSortType>(
    GlobalFeedSortKey,
    GlobalFeedSortType.TOP
  )

  const limit = 20
  const feedParams: GetGlobalFeedParams = { query: { sort: sortBy, limit: limit } }
  if (sortBy === GlobalFeedSortType.TOP) feedParams.query.period = period
  let [feedPages, , , feedPager, feedRequests, firstPageLoaded] = useGetGlobalFeed(feedParams, {
    paused: periodPending || sortPending
  })

  const isPending = feedRequests[0]?.stage !== RequestStage.Success
  if (sortBy === GlobalFeedSortType.TOP && ssrPeriod === period && publicTopFeed && !firstPageLoaded)
    feedPages = [publicTopFeed]
  if (sortBy === GlobalFeedSortType.NEW && publicNewFeed && !firstPageLoaded) feedPages = [publicNewFeed]

  let feedItems: FeedItem[] = feedPages?.flatMap(a => a.items) || []
  const isEmpty = feedItems.length === 0
  const firstItem = feedItems[0]
  const isPostedRecently = hasPostedRecently(firstItem, user?.user_uuid)
  if (isPostedRecently) feedItems.shift()

  const router = useRouter()
  const feedRef = useRef<HTMLDivElement | null>(null)
  useEffect(() => {
    if (!feedRef.current) return
    // Scroll to top, when clicking feed icon or refreshing page
    if (!firstPageLoaded && router.asPath === Routes.Home) {
      feedRef.current.closest(`.${ScrollerClass}`)?.scrollTo({ top: 0 })
    }
  }, [firstPageLoaded, router])

  const loadMore = useCallback(() => !feedPager.terminal && feedPager.next(), [feedPager])

  const prependItem = usePrependPost(
    // Using hard coded key ad feedRequests be filtering by Top instead of New
    `memory::get-global-feed:GET:{"query":{"sort":"New","limit":${limit}}}0:`,
    () => sortBy === GlobalFeedSortType.TOP && setSortBy(GlobalFeedSortType.NEW)
  )

  return (
    <PanelGrid>
      <PaneColumn ref={feedRef}>
        <PaneHeader title="Home" />
        {isLoggedIn && <FeedCreatePost prependItem={prependItem} redirect={user?.private} />}
        <GlobalFeedFilters
          period={period}
          setPeriod={period => setPeriod(period)}
          sortBy={sortBy}
          setSortBy={sortBy => setSortBy(sortBy)}
          // Used only to close the filter dropdown
          pending={isPending}
        />
        <InAppMessageList />
        <SkeletonWrapper
          pending={!firstPageLoaded && !feedItems.length}
          skeleton={<FeedSkeleton />}
          failed={
            isEmpty && (
              <Spacing padding={[1, 0]}>
                <CSText block centered>
                  No posts to display
                </CSText>
              </Spacing>
            )
          }
        >
          {firstItem && isPostedRecently && <AppendedNewPost item={firstItem} cardLayout={CardLayoutTypes.Upvote} />}
          <WindowedFeed key={sortBy} items={feedItems} loadMore={loadMore} cardLayout={CardLayoutTypes.Upvote} />
          {!feedPager.terminal && <BrandSpinner />}
          {feedPager.terminal && <AllCaughtUp />}
        </SkeletonWrapper>
      </PaneColumn>
      {!isLoggedIn && (
        <PaneColumn>
          <HomeDashboard />
        </PaneColumn>
      )}
    </PanelGrid>
  )
}

export function hasPostedRecently(item: FeedItem | undefined, userUuid?: string) {
  if (!item || !userUuid || userUuid !== item.user_uuid) return false
  const now = DateTime.local()
  const past = DateTime.fromISO(item.created_at)
  const diff = now.diff(past, 'seconds')
  return diff.seconds < 5
}

export function usePrependPost(feedKey: string, callback?: () => void) {
  // const feedKey = feedRequests[0]?.cacheKey
  // Using hard coded key ad feedRequests be filtering by Top instead of New
  // const feedKey = 'memory::get-global-feed:GET:{"query":{"sort":"New"}}0:'
  let client = useClient()
  const forceRender = useForceRender()
  const prependItem = useCallback(
    async (feedItem: FeedItem) => {
      feedItem.reason_code = FeedReason.You
      feedItem.reason_text = 'Your Post'
      const firstPage = feedKey && client.cache.get(feedKey)

      if (firstPage) {
        firstPage.success?.payload.items.unshift(feedItem)
        client.cache.set(feedKey, firstPage)
        forceRender()
      }
      callback && callback()
    },
    [callback, client.cache, feedKey, forceRender]
  )

  return prependItem
}

const animateTop = { y: '0', opacity: 1, maxHeight: '1000px', overflow: 'unset' }
const initialTop = { y: '-100%', opacity: 0, maxHeight: '0px', overflow: 'hidden' }
const duration = { duration: 0.7 }
export function AppendedNewPost({ item, cardLayout }: { item: FeedItem; cardLayout?: CardLayoutTypes }) {
  const wrapperRef = useRef<HTMLDivElement | null>(null)

  const onLayoutAnimationComplete = () => {
    requestAnimationFrame(() => {
      if (!wrapperRef.current) return
      wrapperRef.current.style.removeProperty('transform')
    })
  }

  return (
    <motion.div
      ref={wrapperRef}
      initial={initialTop}
      animate={animateTop}
      transition={duration}
      key={item.uuid}
      style={{ zIndex: ZIndexLayers.SlightlyUp, position: 'relative' }}
      onAnimationComplete={onLayoutAnimationComplete}
    >
      <ContentTypeCard item={item} cardLayout={cardLayout} />
    </motion.div>
  )
}

export default GlobalFeed
